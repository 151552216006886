<template>
    <div class="bg-white box-shadow border-r px-20 pb-20" v-loading="loading">
        <div class="d-flex-center px-15 border-bottom">
            <h4 class="mr-auto color-333">{{ $t('account.micro_contract') }}</h4>
            <el-button type="primary" size="mini" icon="el-icon-refresh" plain @click="fatchRecord()">{{ $t('base.refresh') }}</el-button>
        </div>
        <el-row :gutter="20" type="flex" justify="center" align="middle" class="market-header px-20 py-15 border-bottom font-14">
            <el-col :span='3'>{{ $t('micro_contract.span1') }}</el-col>
            <el-col :span='3'>{{ $t('micro_contract.span2') }}</el-col>
            <el-col :span='3'>{{ $t('micro_contract.span3') }}</el-col>
            <el-col :span='3'>{{ $t('micro_contract.span4') }}</el-col>
            <el-col :span='4'>{{ $t('micro_contract.span5') }}</el-col>
            <el-col :span='4'>{{ $t('micro_contract.span6') }}</el-col>
            <el-col :span='3'>{{ $t('micro_contract.span7') }}</el-col>
            <el-col class="text-right" :span='3'>{{ $t('micro_contract.span8') }}</el-col>
        </el-row>
        <el-row style="font-size:12px;" v-for="(item, index) in list" :key="index" :gutter="20" type="flex" justify="center" align="middle" class="market-header px-20 py-15 border-bottom font-14">
            <el-col :span='3'>
                <el-tag v-if="item.type" type='danger' style="width:52px;text-align:center" effect="dark" hit>Down</el-tag>
                <el-tag v-else type="success" style="width:52px;text-align:center" effect="dark" hit>Up</el-tag>
            </el-col>
            <el-col :span='3'>{{ item.pair&&item.pair.toUpperCase() }} / {{ item.coin&&item.coin.toUpperCase() }}</el-col>
            <el-col :span='3'>{{ Number(item.amount) }}</el-col>
            <el-col :span='3'>{{ item.due_time }}s / {{ Number(item.profit)*100+'%' }}</el-col>
            <el-col :span='4' :class="item.state==1?'color-s':item.state==3?'color-d':'---'">
                {{ item.state==1?'+'+item.total+' usd':item.state==3?'-'+item.amount+' usd':'---'}}
            </el-col>
            <el-col :span='4'>{{ item.state==0?'---':item.end_price }}</el-col>
            <el-col :span='3'>{{ item.state==0?'---':item.end_time }}</el-col>
            <el-col class="text-right" :span='3'>
                <el-tag effect="plain" hit :type="
                item.state==1?'success':item.state==3?'danger':'info'
                "
                style="width:68px;text-align:center"
                >
                {{ state1[item.state] }}
                </el-tag>
            </el-col>
        </el-row>
        <div class="d-flex-center pt-20">
            <el-pagination
                :hide-on-single-page="true"
                background
                layout="prev, pager, next"
                :page-size="pageSize"
                :current-page="page"
                :total="total"
                @current-change="onPage"
            />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            query: { ...this.$route.query },
            // list: {
            //     per_page: 1,
            //     current_page: 1,
            //     total: 0,
            //     data: []
            // },
            list: [],
            state: [
                ['primary', 'Waiting'],
                ['success', 'Win'],
                ['info', 'Draw'],
                ['danger', 'Fail']
            ],
            state1: [
                this.$t('micro_contract.state1'),
                this.$t('micro_contract.state2'),
                '',
                this.$t('micro_contract.state4'),
            ],
            page:1,
            pageSize:10,
            total:0,
        }
    },
    created: function() {
        this.fatchRecord()
    },
    methods: {
        fatchRecord: async function () {
            try {
                this.loading = true
                const { data } = await this.$request.post('v1/time_set/getOrderList', {page:this.page,pageSize:this.pageSize})
                this.list = data.list
                this.total = data.total
                this.loading = false
            } catch (error) {
                this.loading = false
            }
            
        },
        // onSearch() {
        //     this.fatchRecord()
        //     this.$router.replace({
        //         query: this.query
        //     })
        // },
        onPage(page) {
            this.page = page
            this.fatchRecord()
        }
    }
}
</script>
<style lang="scss" scoped>
//
</style>